import { Col, Row, Typography, Space } from 'antd';
import Icon, { TwitterOutlined, RedditOutlined, GithubOutlined, YoutubeOutlined, InstagramOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useIsBigScreen } from '../app/hooks'
import { LightDarkSwitch } from '../features/lightDarkSwitch/LightDarkSwitch';
import { CopyToClipboard } from '../components/CopyToClipboard'
import { DefinedWorkflowLink } from '../components/DefinedWorkflowLink'
import { DateTime } from 'luxon';

import { default as DiscordIcon } from '../images/discord'
import { default as XSocialNetworkIcon } from '../images/x_logo'
const { Paragraph: P } = Typography



function Section0() {
    const isBigScreen = useIsBigScreen()

    const firstSocial = <>
        <a href='https://twitter.com/pyroprompts' target='blank'><TwitterOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://x.com/pyroprompts' target='blank'><XSocialNetworkIcon style={{ fontSize: '20px' }} /></a>
        <a href='https://www.reddit.com/r/PyroPrompts/' target='blank'><RedditOutlined style={{ fontSize: '22px' }} /></a>
        <a href='https://discord.gg/tnyGScTc6K' target='blank'><Icon component={DiscordIcon} style={{ fontSize: '22px' }} /></a>
    </>

    const secondSocial = <>
        {/* <a href='https://github.com/pyroprompts' target='blank'><GithubOutlined style={{ fontSize: '22px' }} /></a> */}
        {/* <a href='https://www.instagram.com/pyroprompts/' target='blank'><InstagramOutlined style={{ fontSize: '22px' }} /></a> */}
        {/* <a href='https://www.youtube.com/channel/UCuHNw_QCpmYRXOFWiEWVu1w' target='blank'><YoutubeOutlined style={{ fontSize: '22px' }} /></a> */}
    </>

    return (
        <>
            <Typography.Title level={3}>
                PyroPrompts
            </Typography.Title>
            <Space size='middle'>
                {firstSocial}
                {/* {secondSocial} */}
            </Space>
            <P></P>
            <P><a><CopyToClipboard text={"info@pyroprompts.com"} content={"info@pyroprompts.com"} placement='right'/></a></P>
            {/* <P style={{color: '#888'}}>Quotes are delayed. Content is not to be considered financial advice.</P>
            <P style={{color: '#888'}}>Copyright © 2022-2023 PyroPrompts.com. </P>
            <P style={{color: '#888'}}>All Rights Reserved. </P> */}
            <P><DefinedWorkflowLink workflowName={'feedback'} text='Give Feedback' includeCreditsTextIfNoCredits={false} /></P>
            <P><a><LightDarkSwitch /></a></P>
            <P style={{fontSize: '0.8rem'}} strong>a <a href='https://heyferrante.com?ref=pyroprompts-footer' target='blank'>heyferrante</a> project</P>
        </>
    )
}

function Section1() {
    return (
        <>
            <Typography.Title level={5}>
                Offerings
            </Typography.Title>

            {/* <h2>Offerings</h2> */}
            {/* <P><Link to={`/integration_offerings`}>Integrations</Link></P>

            {/* <P><Link to={`/prompts`}>PromptWatch</Link></P> */}
            <P><Link to={`/prompt_guide_2023`}>Prompt Guide</Link></P>
            {/* <P><Link to={`/openai_gpts_guide`}>Prompt Guide 2023</Link></P> */}
            {/* <P><Link to={`/store`}>Pricing</Link></P> */}

            <P><Link to={`/workflow_automation`}>Workflow Automation</Link></P>
            {/* <P><Link to={`/dashboards`}>Dashboards</Link></P> */}
            {/* <P><Link to={`/prompts`}>Bulk AI</Link></P> */}
            {/* <P><Link to={`/telegram_bot`}>Data Sets</Link></P> */}
            {/* <P><Link to={`/ai_monitoring`}>AI Monitoring</Link></P> */}
            <P><Link to={`/newsletter`}>Newsletter</Link></P>
            <P><Link to={`/documentation`}>Documentation</Link></P>
            <P><Link to={`/extension`}>Chrome Extension</Link></P>
            <P><Link to={`/custom_gpts`}>Custom GPTs</Link></P>

            {/* <P><Link to={`/ai_for_digital_marketers`}>For Realtors</Link></P> */}
            
            {/* <P><Link to={`/consulting`}>AI Consulting</Link></P> */}
            {/* <P><Link to={`/advertising`}>Advertising</Link></P> */}
            {/* <P><Link to={`/enterprise`}>Enterprise Solutions</Link></P> */}

        </>
    )
}


function Section2() {
    const isBigScreen = useIsBigScreen()
    return (
        <>
            {/* <Typography.Title level={5}>
                Solutions
            </Typography.Title> */}
            {/* <P><Link to={`/ai_solutions_for_content_creators`}>Content Creators</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_teachers`}>Teachers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_project_managers`}>Project Managers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_web_developers`}>Web Developers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_machine_learning_engineers`}>Machine Learning Engineers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_product_designers`}>Product Designers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_marketers`}>Marketers</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_sales`}>Sales</Link></P> */}
            {/* <P><Link to={`/ai_solutions_for_lawyers`}>Lawyers</Link></P> */}

            {isBigScreen && (<h2>&nbsp;</h2>)}

            {/* <P><Link to={`/affiliate`}>Affiliate</Link></P> */}
            {/* <P><Link to={`/advertising`}>Blog</Link></P> */}
            <P><Link to={`/ai_for_digital_marketers`}>For Digital Marketers</Link></P>

            <P><Link to={`/store`}>Pricing</Link></P>
            <P><Link to={`/consulting`}>AI Consulting</Link></P>
            {/* <P><Link to={`/on_demand_analysis`}>On-Demand Analysis</Link></P> */}
            {/* <P><Link to={`/analysis_tracking`}>Analysis Tracking (pay per symbol)</Link></P> */}
            {/* <P><Link to={`/custom_strategy`}>Custom AI &amp; Feature Development</Link></P> */}
            <P><Link to={`/advertising`}>Advertising</Link></P>
            <P><Link to={`/enterprise`}>Enterprise Solutions</Link></P>
            {/* <P><Link to={`/workflow-focus-group`}>Focus Group (hide for now)</Link></P> */}

        </>
    )
}

function Section3() {
    return (
        <>
            {/* <h2>About</h2> */}
            <Typography.Title level={5}>
                About
            </Typography.Title>
            {/* <P><Link to={`/team`}>Team</Link></P> */}
            <P><a href={`https://blog.pyroprompts.com`} target='blank'>Blog</a></P>
            <P><Link to={`/company`}>Company</Link></P>
            {/* <P><Link to={`/careers`}>Careers</Link></P> */}
            {/* <P><Link to={`/investors`}>Investors</Link></P> */}
            <P><Link to={`/terms_and_conditions`}>Terms &amp; Conditions</Link></P>
            <P><Link to={`/privacy_policy`}>Privacy Policy</Link></P>
            {/* <P><Link to={`/community`}>Community</Link></P> */}
            <P><Link to={`/contact_us`}>Contact Us</Link></P>
            {/* <P><Link to={`/pricing`}>Pricing</Link></P> */}
        </>
    )
}


export function FooterContent() {
    const dt = DateTime.now();
    // TODO - sort out stacking responsiveness
    // span={6} for 4 columns. 24 columns. 1 of 4 and 4 of 5 could work. Need content.
    return (
        <div className="footer-wrapper">
            <Row className="footer-section" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section0 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section1 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section2 />
                </Col>
                <Col span={6} xs={24} md={6} className="gutter-row">
                    <Section3 />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <P style={{textAlign: 'center'}}>Copyright © 2023-{dt.year} PyroPrompts.com. All Rights Reserved. </P>
                </Col>
            </Row>
        </div>
    )
}
